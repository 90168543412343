@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import 'Bootstrap.scss';
@import '~bootstrap/scss/bootstrap';

html,
body {
	font: 14px 'Poppins', sans-serif;
	line-height: 1.5;
}

#root {
	overflow: hidden;
}

a {
	text-decoration-style: dotted;

	&:hover,
	&:focus-visible {
		text-decoration-style: solid;
	}
}

input,
select,
button {
	&:focus-visible {
		box-shadow: 0 0 0 2px white !important;
	}
}

// Animations
@keyframes spin {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
